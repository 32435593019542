import * as THREE from 'three'

// basic three.js component example

export default class Plane extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    // these can be used also in other methods
    this.webgl = webgl
    this.options = options

    const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -10);

    // add it to the group,
    // later the group will be added to the scene
    this.add(this.plane)
  }

}
