import * as THREE from 'three'

// basic three.js component example

export default class PointOfIntersection extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    // these can be used also in other methods
    this.webgl = webgl
    this.options = options

    const pointOfIntersection = new THREE.Vector3();

    // add it to the group,
    // later the group will be added to the scene
    this.add(this.pointOfIntersection)
  }
}
