import { EffectPass, VignetteEffect } from 'postprocessing'
import * as THREE from 'three'
import WebGLApp from './lib/WebGLApp'
import assets from './lib/AssetManager'
import Misha from './scene/Misha'
import PointOfIntersection from './scene/PointOfIntersection'
import Mouse from './scene/Mouse'
import Plane from './scene/Plane'
import { addNaturalLight } from './scene/lights'
import { addScreenshotButton, addRecordButton } from './scene/screenshot-record-buttons'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// true if the url has the `?debug` parameter, otherwise false
window.DEBUG = window.location.search.includes('debug')

// grab our canvas
const canvas = document.querySelector('#app')

// setup the WebGLRenderer
const webgl = new WebGLApp({
  canvas,
  // set the scene background color
  background: '#111',
  backgroundAlpha: 0,
  // enable gamma correction, read more about it here:
  // https://www.donmccurdy.com/2020/06/17/color-management-in-threejs/
  gamma: true,
  // enable postprocessing
  postprocessing: true,
  // show the fps counter from stats.js
  showFps: window.DEBUG,
  // enable OrbitControls
  orbitControls: window.DEBUG,
  // Add the controls pane inputs
  controls: {
    roughness: 0,
    movement: {
      speed: {
        value: 2,
        max: 100,
        scale: 'exp',
      },
      frequency: { value: 0.04, max: 5 },
      amplitude: { value: 0.02, max: 2 },
    },
  },
  showWorldWireframes: false,
  hideControls: !window.DEBUG,
  cameraPosition: new THREE.Vector3(-4, 0, -3)
  // enable cannon-es
  // world: new CANNON.World(),
})

// attach it to the window to inspect in the console
if (window.DEBUG) {
  window.webgl = webgl
}

// hide canvas
webgl.canvas.style.visibility = 'hidden'

// load any queued assets
assets.load({ renderer: webgl.renderer }).then(() => {
  // add any "WebGL components" here...
  // append them to the scene so you can
  // use them from other components easily
  webgl.scene.plane = new Plane(webgl)
  webgl.scene.add(webgl.scene.plane)

  webgl.scene.misha = new Misha(webgl)
  webgl.scene.add(webgl.scene.misha)

  // webgl.camera.rotation.set(0,180,0);

  // lights and other scene related stuff
  addNaturalLight(webgl)

  // postprocessing
  // add an existing effect from the postprocessing library
  webgl.composer.addPass(new EffectPass(webgl.camera, new VignetteEffect()))

  // add the save screenshot and save gif buttons
  if (window.DEBUG) {
    addScreenshotButton(webgl)
    addRecordButton(webgl)
  }

  // webgl.camera.lookAt(webgl.scene.misha.position);

  // show canvas
  webgl.canvas.style.visibility = ''


  // start animation loop
  webgl.start()
})

// gsap.registerPlugin(ScrollTrigger);
    

ScrollTrigger.defaults({
  immediateRender: false,
  // ease: "power1.inOut",
});

let misha_tl = gsap.timeline({

    scrollTrigger: {
      trigger: ".scroll-1",
      start: "top top", 
      endTrigger: ".scroll-4",
      end: "bottom bottom", 
      scrub: 1
    }
  
});

let name = document.getElementById("name");
let profile_left = document.getElementById("profile-left");
let profile_right = document.getElementById("profile-right");
let profile_role = document.getElementById("profile-role");
let profile_text = document.getElementById("profile-text");
let text_left = document.getElementById("text-left");
let text_right = document.getElementById("text-right");
let b_left = document.getElementById("left");
let b_right = document.getElementById("right");
let b_top = document.getElementById("top");
let b_bottom = document.getElementById("bottom");

misha_tl
  .to(name, {color: "#000", scale: 0.5, x:20, y: -window.innerHeight/2}, "step1")
  .to(profile_left, {x:20, y: -window.innerHeight/2.4}, "step1")
  .to(webgl.camera.position, { x: -0.1 }, "step1")
  .to(webgl.scene.rotation, { y: -1.6 }, "step1")
  .to(b_left, { x: -25 }, "step2") 
  .to(b_right, { x: 25 }, "step2") 
  .to(b_top, { y: -25 }, "step2") 
  .to(b_bottom, { y: 25 }, "step2") 
  .to(profile_role, {opacity:1 }, "step2")
  .to(profile_text, {opacity:1 })
  // .to(document.body, {backgroundColor: '#fff'})
  .to(webgl.scene.rotation, { y: 0 }, "step3")
  .to(webgl.camera.position, { x: -4 }, "step3")
  .to(name, {opacity:0 }, "step3")
  .to(profile_role, {opacity:0 }, "step3")
  .to(profile_text, {opacity:0 }, "step3")
  .to(webgl.scene.rotation, { y: 1 }, "step4")
  .to(webgl.camera.position, { x: -5.1 }, "step4")
  .to(text_left, {x: '100%'}, "step4")
  .to(text_right, {x: '-100%'}, "step4")
  .to(b_left, { x: 0 }, "step5") 
  .to(b_right, { x: 0 }, "step5") 
  .to(b_top, { y: 0 }, "step5") 
  .to(b_bottom, { y: 0 }, "step5") 
  .to(text_left, {x: '200%'}, "step5")
  .to(text_right, {x: '-200%'}, "step5")
  .from(profile_right, { x: profile_right.offsetWidth }, "step5") 
;
